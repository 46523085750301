const headers = langcheckout['headers'];
let freeshipping = false;
let urlParams = '';

let l;
let c;

$(function () {
    localStorage.clear();

    getUrlParams();

    // START SLIDER
    $(".slider1 .owl-carousel").owlCarousel({
        items:1,
        margin:5,
        navigation:true,
        pagination:true,
        nav:true,
        autoplay: false,
        loop:true,
        dots:true,
        autoHeight: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    });

    // // PLAY VIDEO
    $(".vdo").click(function() {
        if ($("#video").get(0).paused) {
            $("#video").trigger('play');
            $(".play").fadeOut(500);
        } else {
            $("#video").trigger('pause');
            $(".play").fadeIn(500);
        }
    });

    getCampaign().then(
        (onResolved) => {
            renderPackages();
        });

    setFomo();
    renderFooterPaymentIcons();
});

function getUrlParams(){
    let newUrl;
    urlParams = '?';
    let keys = Object.keys(paramsArray);

    const containsL = keys.includes('l');
    const containsC = keys.includes('c');

    if(!containsL){
        paramsArray.l = '1';
    }else{
        if(paramsArray.l !== '1'){
            paramsArray.l = '1';
        }
    }

    if(!containsC){
        paramsArray.c = '1';
    }

    keys = Object.keys(paramsArray);

    keys.forEach((key, index) => {
        if (index > 0) {
            urlParams += '&';
        }

        if(key === 'geo' && (paramsArray[key] === '' || paramsArray[key] !== geoCheckout)){
            paramsArray[key] = geoCheckout;
        }

        // url param: landing page
        if(key === 'l'){
            if(paramsArray[key] !== ''){
                l = paramsArray[key];
            }else{
                l = '1';
                paramsArray.l = '1';
            }
        }

        // url param: checkout page
        if(key === 'c'){
            if(paramsArray[key] !== ''){
                c = paramsArray[key];
            }else{
                c = '1';
                paramsArray.c = '1';
            }
        }

        urlParams += key + '=' + paramsArray[key];

        newUrl = window.location.pathname + urlParams;
    });

    window.history.replaceState({}, '', newUrl);

    setDataBasedOnCheckoutVersion();
}

function setDataBasedOnCheckoutVersion(){
    const searchParams = new URLSearchParams(window.location.search);
    checkoutVersion = parseInt(searchParams.get('c'), 10);

    if(checkoutVersion === 1){
        lowestOfferArrayId = 0;
        highestOfferArrayId = 3;
    }else if(checkoutVersion === 2){
        lowestOfferArrayId = 7;
        highestOfferArrayId = 10;
    }else if(checkoutVersion === 3){
        lowestOfferArrayId = 0;
        highestOfferArrayId = 3;
    }else if(checkoutVersion === 4){
        lowestOfferArrayId = 7;
        highestOfferArrayId = 10;
    }else if(checkoutVersion === 5){
        lowestOfferArrayId = 12;
        highestOfferArrayId = 15;
    }else if(checkoutVersion === 6){
        lowestOfferArrayId = 0;
        highestOfferArrayId = 3;
    }else if(checkoutVersion === 7){
        lowestOfferArrayId = 0;
        highestOfferArrayId = 3;
    }

    if(checkoutVersion === 2 || checkoutVersion === 4 || checkoutVersion === 5 || checkoutVersion === 6){
        freeshipping = true;
    }else{
        freeshipping = false;
    }
}

function renderPackages(){
    const template = (i, mostpopular, bestdeal) => `
        <div class="upsell-list__item--heading ps1" style="display: ${mostpopular === 3 ? 'absolute' : 'none'};">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path fill="currentColor" d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z" />
            </svg>
            <span></span>
        </div>
        <div class="upsell-list__item--heading ps16" style="display: ${bestdeal === 4 ? 'absolute' : 'none'};">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path fill="currentColor" d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z" />
            </svg>
            <span></span>
        </div>
        <div class="upsell-list__item--title" data-product-title="">
            <h3></h3>
        </div>
        <div class="upsell-list__item--info-text">
            <span></span>
        </div>
        <div class="upsell-list__item--row">
            <div class="upsell-list__item--img-wrap">
                <div class="upsell-list__item--img-block">
                    <div class="upsell-list__item--img media media--transparent">
                        <img id="ProductImage${i}" src="" sizes="(min-width: 1200px) 366px, (min-width: 750px) calc((100vw - 130px) / 3), calc((100vw - 50px) / 2)" alt="" height="690" width="1020" loading="lazy" data-product-img>
                    </div>
                </div>
            </div>
            <div class="upsell-list__item--info-mobile">
                <div class="upsell-list__item--info-mobile--title" data-product-title="">
                    <h3></h3>
                </div>
                <div class="upsell-list__item--info-mobile--save preselect-no-freeshipping">
                    <span></span>
                </div>
            </div>
            <div class="upsell-list__item--col">
                <div class="upsell-list__item--price">
                    <div class="upsell-list__item--price-wrap preselect-no-freeshipping">
                        <div class="upsell-list__item--price-compare__wrap">
                            <div class="upsell-list__item--price-compare" data-product-compare-price="">
                                <p></p>
                            </div>
                            <div class="upsell-list__item--price-save">
                                <span></span>
                            </div>
                        </div>
                        <div class="upsell-list__item--price-real" data-product-price="">
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="upsell-list__item--btn">
            <a class="button btn_order_now" data-id="${i}" data-bundle-qty="1" id="product${i}" data-cid="${i}" data-product-per-price="" data-product-name="" data-product-total="" data-product-shipping-price="" data-product-image="" data-product-qty="1" data-voucher="" data-product-buy-button></a>
            <div class="mt-3 order-btn-freeshipping">
                <i class="fas fa-truck me-2"></i>
            </div>
        </div>
<!--        <div class="upsell-list__upsell section-template&#45;&#45;16495976153308__1661980149272aaa2c-padding scrollbar" data-product-mobile-modal>-->
<!--            <div class="upsell-list__upsell-info">-->
<!--                <div class="upsell-list__upsell-info&#45;&#45;close" data-close-btn>-->
<!--                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <rect y="1.15504" width="1.63349" height="23.8217" rx="0.816745" transform="rotate(-45 0 1.15504)" fill="black" />-->
<!--                        <rect x="16.8457" y="0.000457764" width="1.63349" height="23.8217" rx="0.816745" transform="rotate(45 16.8457 0.000457764)" fill="black" />-->
<!--                    </svg>-->
<!--                </div>-->
<!--                <div class="upsell-list__upsell-info&#45;&#45;left">-->
<!--                    <div class="upsell-list__upsell-info&#45;&#45;image-offer active">-->
<!--                        <span></span>-->
<!--                    </div>-->
<!--                    <div class="upsell-list__upsell-info&#45;&#45;image-wrap">-->
<!--                        <div class="upsell-list__upsell-info&#45;&#45;image-block">-->
<!--                            <div class="upsell-list__upsell-info&#45;&#45;image media media&#45;&#45;transparent" data-upsell-img-padding>-->
<!--                                <img src="" alt="" height="690" width="1020" loading="lazy" sizes="(min-width: 1200px) 550px, (min-width: 750px) calc((100vw - 130px) / 2), 300px">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="upsell-list__upsell-info&#45;&#45;text-wrap">-->
<!--                    <div class="upsell-list__upsell-title no-margin">-->
<!--                        <h3></h3>-->
<!--                    </div>-->
<!--                    <div class="upsell-list__upsell-qp&#45;&#45;wrap">-->
<!--                        <div class="upsell-list__upsell-info&#45;&#45;text-quantity active" data-item-quantity>-->
<!--                            <p class="upsell-list__upsell-info&#45;&#45;text-quantity&#45;&#45;title"></p>-->
<!--                            <div class="upsell-list__upsell-info&#45;&#45;text-quantity-row">-->
<!--                                <button class="upsell-list__upsell-info&#45;&#45;text-quantity&#45;&#45;change" data-item-quantity-decrease>-</button>-->
<!--                                <span class="upsell-list__upsell-info&#45;&#45;text-quantity&#45;&#45;amount" data-item-quantity-display>1</span>-->
<!--                                <button class="upsell-list__upsell-info&#45;&#45;text-quantity&#45;&#45;change" data-item-quantity-increase>+</button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="upsell-list__upsell-info&#45;&#45;text-price">-->
<!--                            <div class="upsell-list__upsell-info&#45;&#45;text-price__compare-wrap">-->
<!--                                <div class="upsell-list__upsell-info&#45;&#45;text-price__compare">-->
<!--                                    <p></p>-->
<!--                                </div>-->
<!--                                <div class="upsell-list__upsell-info&#45;&#45;text-price__save">-->
<!--                                    <span></span>-->
<!--                                    <span>&nbsp;</span>-->
<!--                                    <span></span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="upsell-list__upsell-info&#45;&#45;text-price__real">-->
<!--                                <p></p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="upsell-list__upsell-info&#45;&#45;text-buttons">-->
<!--                        <button class="button upsell-list__upsell-info&#45;&#45;text-add-btn" data-product-add></button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
`;

    const container = document.querySelector(".upsell-list__content");

    for (let i = lowestOfferArrayId; i <= highestOfferArrayId; i++) {
        let itemId;
        let refId;
        let item = document.createElement("li");
        item.className = "upsell-list__item orderselect";

        itemId = i + 1;

        if(checkoutVersion === 1){
            refId = i + 1;
        }else if(checkoutVersion === 2){
            refId = i - 6;
        }else if(checkoutVersion === 3){
            refId = i + 1;
        }else if(checkoutVersion === 4){
            refId = i - 6;
        }else if(checkoutVersion === 5){
            refId = i - 11;
        }else if(checkoutVersion === 6){
            refId = i + 1;
        }else if(checkoutVersion === 7){
            refId = i + 1;
        }

        item.setAttribute("onclick", `goToCheckoutWithPreselect(${i + 1})`);
        item.innerHTML = template(itemId, refId, refId);

        let oldPrice = parseFloat(offers[i].price_retail_total);
        let newPrice = parseFloat(offers[i].price_total);

        let packageDiscount = oldPrice - newPrice;

        item.dataset.productPerPrice = lang[`DATA_PRODUCT_${refId}_SALE_PRICE_1`];
        item.dataset.productName = lang[`TEXT_PRICE_${refId}_NAME`];
        item.dataset.productTotal = lang[`DATA_PRODUCT_${refId}_SALE_PRICE_1`];
        item.dataset.productShippingPrice = lang[`DATA_PRODUCT_${refId}_SHIPPING_PRICE_1`];
        item.dataset.productImage = lang[`IMG_PRICE_${refId}`];
        item.dataset.productComparePrice = lang[`DATA_PRODUCT_${refId}_FULL_PRICE_1`];
        item.dataset.productSavings = `${lang[`DATA_PRODUCT_${refId}_SAVE_PRICE_1`]} ${lang['CURRENCY']}`;

        item.querySelector('.ps1 span').textContent = lang[`TEXT_PRICE_3_HEADING`];
        item.querySelector('.ps16 span').textContent = lang[`TEXT_PRICE_4_HEADING`];

        item.querySelector('.upsell-list__item--title h3').textContent = lang[`TEXT_PRICE_${refId}_NAME`];
        item.querySelector('.upsell-list__item--info-mobile--title h3').textContent = lang[`TEXT_PRICE_${refId}_NAME`];
        item.querySelector('.upsell-list__item--info-text span').textContent = lang[`TEXT_PRICE_${refId}_COST_TITLE_2`];
        item.querySelector('img').src = lang[`IMG_PRICE_${refId}`];
        item.querySelector('img').alt = `${lang[`TEXT_PRICE_${refId}_NAME`]} Image`;

        item.querySelector('.preselect-no-freeshipping .upsell-list__item--price-compare p').textContent = lang['CURRENCY'] + offers[i].price_retail_total.replace('.', ",");
        item.querySelector('.preselect-no-freeshipping .upsell-list__item--price-save span').textContent = `${lang['TEXT_SAVE']} ` + lang['CURRENCY'] + ' ' + Math.round(packageDiscount);
        item.querySelector('.preselect-no-freeshipping .upsell-list__item--price-real p').innerHTML = lang['CURRENCY'] + offers[i].price_total.split('.')[0];
        item.querySelector(".preselect-no-freeshipping .upsell-list__item--price-real p").appendChild(document.createElement('span')).classList.add('price-comma');
        item.querySelector(".preselect-no-freeshipping .upsell-list__item--price-real p .price-comma").textContent = ',' + offers[i].price_total.substring(offers[i].price_total.length - 2);
        item.querySelector('.preselect-no-freeshipping span').textContent = `${lang['TEXT_SAVE']} ` + lang['CURRENCY'] + Math.round(packageDiscount);

        item.querySelector('.btn_order_now').textContent = lang['TEXT_ORDER_BTN'];
        item.querySelector('.order-btn-freeshipping').innerHTML = `<i class="fas fa-truck me-2"></i>${lang['TEXT_FREE_SHIPPING']}`;

        // const upsellInfo = item.querySelector('.upsell-list__upsell-info');
        // upsellInfo.querySelector('.upsell-list__upsell-info--image-offer span').textContent = lang[`TEXT_PRICE_${refId}_SAVE`];
        // upsellInfo.querySelector('.upsell-list__upsell-title h3').textContent = lang[`TEXT_PRICE_${refId}_NAME`];
        // upsellInfo.querySelector('.upsell-list__upsell-info--image img').src = lang[`IMG_PRICE_${refId}`];
        // upsellInfo.querySelector('.upsell-list__upsell-info--image img').alt = `${lang[`TEXT_PRICE_${refId}_NAME`]} Image`;
        // upsellInfo.querySelector('.upsell-list__upsell-info--text-quantity--title').textContent = lang['TEXT_UPSELL_AMT_TXT'];
        // upsellInfo.querySelector('.upsell-list__upsell-info--text-price__compare p').textContent = lang[`TXT_PRICE_${refId}_COST_BEFORE`];
        // upsellInfo.querySelector('.upsell-list__upsell-info--text-price__save span').textContent = lang[`TEXT_PRICE_${refId}_SAVE`];
        // upsellInfo.querySelector('.upsell-list__upsell-info--text-price__save').innerHTML += `<span>&nbsp;</span><span>${lang['CURRENCY']}${lang[`DATA_PRODUCT_${refId}_SAVE_PRICE_1`]}</span>`;
        // upsellInfo.querySelector('.upsell-list__upsell-info--text-price__real p').textContent = lang[`TXT_PRICE_${refId}_COST_NOW`];
        // upsellInfo.querySelector('.upsell-list__upsell-info--text-add-btn').textContent = lang['TEXT_ORDER_BTN'];

        container.appendChild(item);
    }

    if(freeshipping){
        $(".order-btn-freeshipping").show();
    }else{
        $(".order-btn-freeshipping").hide();
    }
}

// GO TO SHOP PAGE
$(".go-to-shop").click(function() {
    let paramstring = '?';
    const keys = Object.keys(paramsArray);

    keys.forEach((key, index) => {
        if (index > 0) {
            paramstring += '&';
        }
        paramstring += key + '=' + paramsArray[key];
    });

    window.location.assign(langcheckout['shop1_URL'] + paramstring);
});

// GO TO REVIEWS
$(".go-to-reviews").click(function() {
    $('html, body'). animate({ scrollTop: $("#reviews"). offset(). top }, 100);
});

// GO TO VIDEOS
$(".go-to-videos").click(function() {
    let paramstring = '?';
    const keys = Object.keys(paramsArray);

    keys.forEach((key, index) => {
        if (index > 0) {
            paramstring += '&';
        }
        paramstring += key + '=' + paramsArray[key];
    });

    window.location.assign(langcheckout['videos1_URL'] + paramstring);
});

$('.go-to-landing').on('click', function() {
    let paramstring = '?';
    const keys = Object.keys(paramsArray);

    keys.forEach((key, index) => {
        if (index > 0) {
            paramstring += '&';
        }
        paramstring += key + '=' + paramsArray[key];
    });

    window.location.assign(langcheckout['landing' + l + '_URL'] + paramstring);
});

function goToCheckoutWithPreselect(preselectId){
    let paramstring = '?';
    const keys = Object.keys(paramsArray);

    keys.forEach((key, index) => {
        if (index > 0) {
            paramstring += '&';
        }
        paramstring += key + '=' + paramsArray[key];
    });

    window.location.assign(langcheckout['checkout' + c + '_URL'] + paramstring + '&preselect=' + preselectId);
}